import React, { ReactElement, useEffect, useState } from "react";
import cn from "classnames";

import {
  AppState,
  EnergyTariffValues,
  RequestState,
  SetState,
  EnergyCalculatorFormValues,
} from "../../types";
import { Input, InputCity, InputStreet, Range } from "../Form";

import { Button } from "../Button";

import { useTariff } from "./hooks";

import { usePromoCode } from "../Contract/CalculatorForm/hooks/usePromocode";
import { validatePromoCode } from "../Contract/validation";

import {
  validateCity,
  validateStreet,
  validateHouseNumber,
  validateEnergyUsage,
  validatePostcode,
} from "./validation";
import styles from "./styles.mod.scss";
import { useSelector } from "react-redux";

interface EnergyCalculatorProps {
  setFormValues: SetState<RequestState<EnergyTariffValues>>;
  forcePromoCode: boolean;
  defaultFormValues?: EnergyCalculatorFormValues;
}

export const EnergyCalculator = ({
  setFormValues,
  forcePromoCode,
  defaultFormValues,
}: EnergyCalculatorProps): ReactElement => {
  const { calculator } = useSelector((state: AppState) => state.contract);
  const {
    citiesPromise,
    cities,
    streetsPromise,
    streets,
    register,
    handleSubmit,
    errors,
    triggerValidation,
    energyUsageRange,
    cityId,
    street,
    setValues,
    clearError,
    handleEnergyUsage,
    handleRange,
    promoCode,
    onSubmit,
    formState,
  } = useTariff(setFormValues, calculator.values);
  const onStreetChange = () => {
    setValues([{ houseNumber: "" }]);
    clearError(["houseNumber"]);
  };

  const promoCodeData = usePromoCode(
    promoCode ? promoCode : "",
    triggerValidation
  );

  const [promoCodeState, setPromoCodeState] = useState<boolean>(false);
  useEffect(() => {
    setPromoCodeState(
      forcePromoCode || (defaultFormValues?.promoCode ? true : false)
    );
  }, []);

  useEffect(() => {
    if (defaultFormValues) {
      for (const [key, value] of Object.entries(defaultFormValues)) {
        setValues([{ [key]: value }], false);
      }
    }
  }, []);

  return (
    <div className={styles.calculator}>
      <form
        className={styles.calculator__form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={cn("form-row", "mb-3", styles.formGroup)}>
          <div
            className={cn(styles.calculator__col, styles.calculator__col_city)}
          >
            <InputCity
              id="postalCode"
              name="postalCode"
              type="number"
              pattern="\d*"
              label="Postleitzahl"
              error={errors.postalCode?.message || errors.cityId?.message}
              placeholder="Pflichtfeld"
              inputRef={register({
                validate: validatePostcode(citiesPromise),
              })}
              cities={cities}
              cityId={cityId ? cityId : ""}
              setCity={setValues}
            />
            <input
              className="d-none"
              name="cityId"
              type="text"
              ref={register({ validate: validateCity })}
            />
          </div>
          <div
            className={cn(
              styles.calculator__col,
              styles.calculator__col_street
            )}
          >
            <InputStreet
              id="street"
              name="street"
              type="text"
              label="Straße"
              error={errors.street?.message}
              placeholder="Optional"
              inputRef={register({ validate: validateStreet(streetsPromise) })}
              streets={streets}
              street={street ? street : ""}
              disabled={!cityId}
              setStreet={setValues}
              triggerValidation={triggerValidation}
              onStreetChange={onStreetChange}
              autoComplete="off"
            />
          </div>
          <div
            className={cn(
              styles.calculator__col,
              styles.calculator__col_houseNumber
            )}
          >
            <Input
              id="houseNumber"
              name="houseNumber"
              type="text"
              label="Nr."
              error={errors.houseNumber?.message}
              placeholder="Optional"
              inputRef={register({
                validate: validateHouseNumber(
                  Number(cityId),
                  street ? street : ""
                ),
              })}
              disabled={!street}
            />
          </div>
        </div>
        <div className={cn("form-row", "mb-3", styles.formGroup)}>
          <div
            className={cn(
              styles.calculator__col,
              styles.calculator__col_energyUsage
            )}
          >
            <Input
              id="energyUsage"
              name="energyUsage"
              type="number"
              pattern="\d*"
              label="kWh / Jahr"
              error={errors.energyUsage?.message}
              placeholder="Optional"
              onChange={handleEnergyUsage}
              inputRef={register({ validate: validateEnergyUsage })}
            />
          </div>
          <div
            className={cn(
              styles.calculator__col,
              styles.calculator__col_energyUsageRange
            )}
          >
            <Range
              id="energyUsageRange"
              name="energyUsageRange"
              label="Personen im Haushalt"
              min={1}
              max={5}
              value={Number(energyUsageRange)}
              onChange={handleRange}
              inputRef={register()}
            />
          </div>
        </div>
        <div
          className={`${cn("form-row", "mb-3", styles.formGroup)} ${
            promoCodeState ? "" : "d-none"
          }`}
        >
          <Input
            id="promoCode"
            name="promoCode"
            type="text"
            label="Aktionscode"
            error={errors.promoCode?.message}
            placeholder="Optional"
            disabled={!promoCodeState}
            onChange={(event) => {
              setValues([{ promoCode: event.target.value }], false);
            }}
            value={promoCode}
            inputRef={register({
              validate: validatePromoCode(Boolean(promoCodeData?.data?.code)),
            })}
            full
          />
        </div>
        <div className={styles.calculator__buttonWrapper}>
          <Button
            type="submit"
            disabled={formState.isSubmitting}
            id="berechnen"
          >
            Berechnen
          </Button>
        </div>
      </form>
    </div>
  );
};
