import React, { ReactElement } from "react";
import { WindowLocation, Location } from "@reach/router";

import SEO from "../components/Seo";
import EnergyCalculatorSection from "../components/EnergyCalculatorSection";

import { parseQueryParams } from "../utils";

import { Tariff, EnergyCalculatorFormValues, UrlParams } from "../types";

function getDefaultFormValues(
  location: WindowLocation
): EnergyCalculatorFormValues {
  const params: UrlParams = parseQueryParams(location.search);
  const defaultFormValues: EnergyCalculatorFormValues = {};
  const {
    postalCode,
    cityId,
    cityName,
    street,
    houseNumber,
    energyUsage,
    energyUsageRange,
    promoCode,
    promoCodeDescription,
  } = params;
  if (postalCode) {
    defaultFormValues.postalCode = postalCode;
  }
  if (cityId) {
    defaultFormValues.cityId = cityId;
  }
  if (cityName) {
    defaultFormValues.cityName = cityName;
  }
  if (street) {
    defaultFormValues.street = street;
  }
  if (houseNumber) {
    defaultFormValues.houseNumber = houseNumber;
  }
  if (energyUsage) {
    defaultFormValues.energyUsage = energyUsage;
  }
  if (energyUsageRange) {
    defaultFormValues.energyUsageRange = energyUsageRange;
  }
  if (promoCode) {
    defaultFormValues.promoCode = promoCode;
  }
  if (promoCodeDescription) {
    defaultFormValues.promoCodeDescription = promoCodeDescription;
  }

  return defaultFormValues;
}

function getDefaultTariff(location: WindowLocation): Tariff {
  const params: UrlParams = parseQueryParams(location.search);
  const defaultTariff: Tariff = {};
  const {
    estimatedPerMonth,
    annual,
    stromee,
    networkCosts,
    consumptionPrice,
    consumptionPricePerKWh,
    minimumTerm,
    greenElectricity,
    stromeeApp,
    promoCode,
    promoCodeDescription,
  } = params;

  if (estimatedPerMonth) {
    defaultTariff.estimatedPerMonth = parseFloat(estimatedPerMonth);
  }
  if (annual) {
    defaultTariff.annual = parseFloat(annual);
  }
  if (stromee) {
    defaultTariff.stromee = parseFloat(stromee);
  }
  if (networkCosts) {
    defaultTariff.networkCosts = parseFloat(networkCosts);
  }
  if (consumptionPrice) {
    defaultTariff.consumptionPrice = parseFloat(consumptionPrice);
  }
  if (consumptionPricePerKWh) {
    defaultTariff.consumptionPricePerKWh = parseFloat(consumptionPricePerKWh);
  }
  if (minimumTerm) {
    defaultTariff.minimumTerm = minimumTerm;
  }
  if (greenElectricity) {
    defaultTariff.greenElectricity = greenElectricity;
  }
  if (minimumTerm) {
    defaultTariff.minimumTerm = minimumTerm;
  }
  if (stromeeApp) {
    defaultTariff.stromeeApp = stromeeApp;
  }
  if (promoCode) {
    defaultTariff.promoCode = promoCode;
  }
  if (promoCodeDescription) {
    defaultTariff.promoCodeDescription = promoCodeDescription;
  }
  return defaultTariff;
}

function typeCheckForm(location: WindowLocation): boolean {
  const defaultFormValues: EnergyCalculatorFormValues = getDefaultFormValues(
    location
  );
  if (defaultFormValues.postalCode) {
    return true;
  }
  return false;
}

function typeCheckTariff(location: WindowLocation): boolean {
  const defaultTariffValues: Tariff = getDefaultTariff(location);
  if (
    defaultTariffValues.estimatedPerMonth &&
    defaultTariffValues.annual &&
    //defaultTariffValues.stromee &&
    defaultTariffValues.networkCosts &&
    defaultTariffValues.consumptionPrice &&
    defaultTariffValues.consumptionPricePerKWh &&
    defaultTariffValues.minimumTerm &&
    defaultTariffValues.greenElectricity &&
    defaultTariffValues.stromeeApp
  ) {
    return true;
  }
  return false;
}

const Calculator = (): ReactElement => {
  return (
    <div>
      <SEO title="stromee Rechner" />

      <Location>
        {({ location }) => (
          <EnergyCalculatorSection
            defaultTariff={
              typeCheckTariff(location)
                ? getDefaultTariff(location) || undefined
                : undefined
            }
            defaultFormValues={
              typeCheckForm(location)
                ? getDefaultFormValues(location) || undefined
                : undefined
            }
            forcePromoCode={false}
            isScroll={false}
          />
        )}
      </Location>
    </div>
  );
};

export default Calculator;
