import React, { ReactElement, useState, useRef } from "react";
import cn from "classnames";

import icon from "../../assets/tarif-bg.png";
import { createRequestState } from "../../utils";
import { EnergyCalculator } from "../EnergyCalculator";
import EnergyTariff from "../EnergyTariff";
import { useScroll } from "../../hooks/useScroll";

import styles from "./styles.mod.scss";
import {
  Tariff,
  EnergyCalculatorFormValues,
  EnergyTariffValues,
} from "../../types";

interface EnergyCalculatorSectionProps {
  isScroll?: boolean;
  defaultTariff?: Tariff;
  defaultFormValues?: EnergyCalculatorFormValues;
  forcePromoCode: boolean;
}
const EnergyCalculatorSection = ({
  defaultFormValues,
  defaultTariff,
  isScroll,
  forcePromoCode,
}: EnergyCalculatorSectionProps): ReactElement => {
  let defaultTariffData: EnergyTariffValues | null = null;
  if (defaultFormValues && defaultTariff) {
    defaultTariffData = {
      tariff: defaultTariff,
      formValues: defaultFormValues,
    };
  }
  const [tariff, setTariff] = useState(
    createRequestState<EnergyTariffValues>(defaultTariffData)
  );

  const calculateRef = useRef(null);
  useScroll(isScroll || false, calculateRef);
  const tariffResultRef = useRef<HTMLDivElement>(null);
  return (
    <section className={cn(styles.calculatorSection, "container")}>
      <h2
        ref={calculateRef}
        id="calculate"
        className={styles.calculatorSection__title}
      >
        stromee Rechner
      </h2>
      <div className="row justify-content-between align-items-center">
        <div
          id="tariff"
          className={cn(styles.calculatorSection__result, "col-lg-5")}
          ref={tariffResultRef}
        >
          <EnergyTariff {...tariff} tariffResultRef={tariffResultRef} />
          <img
            className={styles.calculatorSection__background}
            src={icon}
            alt="tariff icon"
          />
        </div>
        <div className="col-lg-6">
          <EnergyCalculator
            defaultFormValues={defaultFormValues}
            forcePromoCode={forcePromoCode}
            setFormValues={setTariff}
          />
        </div>
      </div>
    </section>
  );
};

export default EnergyCalculatorSection;
